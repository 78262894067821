import React, {Fragment, useContext} from 'react';
import {Link} from 'gatsby';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import * as colors from '@ant-design/colors';

import {Typography, Space, Form, Input, Button, Checkbox, Alert, Row, Col} from 'antd';
import {LocaleContext} from '~src/context';
import styled from 'styled-components';
import {Routes} from '~src/utils/routes';
const {Title} = Typography;

interface IProps {}

const IconStyled = {
  color: colors.presetPrimaryColors.blue,
};

const validateMessages = {
  required: 'Обязательное поле',
  types: {
    email: 'Это должен быть email',
  },
};

const Content: React.FC<IProps> = props => {
  const {dispatch, auth} = useStoreon<IState, IEvents>('auth');

  const {
    pages: {
      admin: {auth: authContext},
    },
  } = useContext(LocaleContext);

  // TODO: разобраться с типами - в AntD по дефолту стоит any
  const handleAuthSubmit = values => {
    dispatch('auth/sign-in', values);
  };

  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <TitleStyled level={4}>{authContext.login.loginTitle}</TitleStyled>
      </Col>
      {auth.error && (
        <Col span={24}>
          <Alert type={'error'} message={auth.error} />
        </Col>
      )}
      <Col span={24}>
        <FormStyled name="auth-form" onFinish={handleAuthSubmit} size={'large'} validateMessages={validateMessages}>
          <Form.Item
            name="userEmail"
            validateTrigger={['onBlur', 'onSubmit']}
            rules={[
              {
                type: 'email',
                required: true,
              },
            ]}
          >
            <Input
              prefix={<authContext.icons.emailIcon style={IconStyled} />}
              name={'userEmail'}
              placeholder={authContext.userName.usernamePlaceholder}
            />
          </Form.Item>
          <Form.Item name="userPassword" rules={[{required: true}]}>
            <Input
              prefix={<authContext.icons.passwordIcon style={IconStyled} />}
              type="password"
              name={'userPassword'}
              placeholder={authContext.password.passwordPlaceholder}
            />
          </Form.Item>
          <FormFooterStyled direction={'vertical'} size={20}>
            <FormAdditionalControlStyled>
              <Form.Item name="withRemember" valuePropName="checked" noStyle>
                <Checkbox>{authContext.controls.rememberCheckboxText}</Checkbox>
              </Form.Item>
              <Link to={Routes.RecoveryStart}>{authContext.passwordReset.passwordResetLinkText}</Link>
            </FormAdditionalControlStyled>
            <ButtonStyled type="primary" htmlType="submit">
              {authContext.controls.submitButtonText}
            </ButtonStyled>
          </FormFooterStyled>
        </FormStyled>
      </Col>
    </Row>
  );
};

const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleStyled = styled(Title)`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

const ButtonStyled = styled(Button)`
  width: 100%;
`;

const FormAdditionalControlStyled = styled(props => <Space {...props} />)`
  justify-content: space-between;
`;

const FormFooterStyled = styled(props => <Space {...props} />)`
  width: 100%;
`;

export default Content;
